import { graphql, navigate } from "gatsby";
import * as React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { Constants } from "../@types/Constants";
import ILoginError from "../@types/ILoginError";
import ILoginUser from "../@types/ILoginUser";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import SuccessModal from "../components/common/SuccessModal/SuccessModal";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import SubTitle from "../components/global/SubTitle/SubTitle";
import Title2 from "../components/global/Title2/Title2";
import { useAppDispatch } from "../hooks";
import { TrackErrorMessage } from "../pageHelpers/ResetPassword/ResetPasswordHelpers";
import { IResetPasswordProps } from "../pageHelpers/ResetPassword/ResetPasswordProps";
import { login } from "../redux/slices/Member/member";
import { guestServiceProvider } from "../services/ServiceProvider";
import * as auth0Client from "../utils/auth0Client";
import PasswordValidation from "../utils/passwordChecklistValidator";

const ResetPassword: React.FC<IResetPasswordProps> = (props) => {
  const _site = props.data.allRlhsite.edges[0].node;
  const dispatch = useAppDispatch();
  const { register, watch, handleSubmit, getValues, trigger, errors } = useForm(
    {
      mode: "onBlur",
    }
  );
  const location = props.location;
  const required = "This field is required.";
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [_isValidToken, _setIsValidToken] = React.useState(false);
  const [isValidatingToken, _setIsValidatingToken] = React.useState(false);
  const [showPasswordChecklist, setPasswordChecklist] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [resetPwdArgs, setResetPwdArgs] = React.useState<{
    auth0UserId: string;
    email: string;
    guestId: string;
    username: string;
    nonce: string;
  }>({
    auth0UserId: "",
    email: "",
    guestId: "",
    username: "",
    nonce: "",
  });

  React.useEffect(() => {
    if (queryParams.message && queryParams.message.toLowerCase().includes("expired")) {
      navigate("/expired-link");
      return;
    }
    const argsObj = queryParams.args && JSON.parse(atob(queryParams.args));
    argsObj.username = argsObj.email ? argsObj.email.replace(/@/g, ".") : "";
    setResetPwdArgs(argsObj);
  }, [location]);

  const handleSuccessClose = () => {
    setShowSuccess(false);
    navigate("/account");
  };

  const handleRevalidatePassword = () => {
    // if confirmpassword field has value then validate it's value against updated newpassword field value
    getValues().confirmpassword && trigger("confirmpassword");
  };

  type ChangePwdParams = {
    email: string;
    password: string;
  };
  const changePwd = async (params: ChangePwdParams) => {
    const { auth0UserId, nonce } = resetPwdArgs;
    const result = await Axios.post(
      `${window.location.origin}/api/signupChangePassword?id=${auth0UserId}&nonce=${nonce}`,
      params,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  };

  type ResetPasswordParams = {
    args: string;
    newPassword: string;
  };

  const changePwdCDP = async (params: ResetPasswordParams) => {
    const guestService = await guestServiceProvider();
    const response = await guestService.resetPassword(
      params.newPassword,
      params.args
    );
    // const result = await Axios.post(
    //   `${window.location.origin}/api/signUpChangePasswordCDP`,
    //   params,
    //   {
    //     headers: {
    //       "Cache-Control": "no-cache",
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    // return result.data;
    return response.completeSignUpProcess;
  };

  const onLogin = async (errObj: ILoginError | null, data: ILoginUser) => {
    if (errObj) {
      setErrorMessage(
        "Oops!! something went wrong while signing you in. Please sign in using new password."
      );
    } else {
      await dispatch(login(data));
      setShowSuccess(true);
    }
  };

  const onSubmit = async (data: any, event: { preventDefault: () => void }) => {
    const initialValues = {
      newpassword: "",
    };
    const request = { ...initialValues, ...data };

    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    const ret = await changePwdCDP({
      newPassword: request.newpassword,
      args: queryParams.args,
    });
    const succeeded = ret.statusCode === "200";

    if (!succeeded) {
      setErrorMessage(
        `An error occurred resetting password. Please try again.`
      );
    } else {
      const username = resetPwdArgs.email;
      if (username) {
        try {
          await auth0Client.getCDPToken(username, request.newpassword, onLogin);
        } catch (error) {
          setErrorMessage(
            `Oops!! something went wrong while signing you in. Please sign in using new password.`
          );
        }
      } else {
        setErrorMessage(`Reset password link has expired or not valid one.`);
      }
    }

    setIsSubmitting(false);
  };

  return (
    <Layout {...{ site: _site }}>
      <SEO title={`Reset Password`} />
      <h1 className="visually-hidden">Need Help Signing In?</h1>
      <Container fluid="sm" className="pt-5 mb-5">
        {isValidatingToken ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Row>
              <Col lg={4}>
                <SubTitle>TRAVEL PASS</SubTitle>
                <Title2>Reset Password</Title2>
              </Col>
              <Col lg={{ span: 7, offset: 1 }}>
                <p>
                  Password requirements: at least 8 characters, a lowercase
                  letter, an uppercase letter, a number, no parts of your
                  username.
                </p>
                {errorMessage && (
                  <ErrorMessageAlert
                    errorType={Constants.ERRORSTYPE.TRAVELPASS}
                    errorSubType={
                      Constants.ERRORSSUBTYPE.TRAVELPASS.RESETPASSWORD
                    }
                    message={errorMessage}
                  >
                    <Alert variant={`danger`}>{errorMessage}</Alert>
                  </ErrorMessageAlert>
                )}
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <Form.Label>New password</Form.Label>
                      <Form.Control
                        type="password"
                        data-tooltip-id="newpassword"
                        name="newpassword"
                        ref={register({
                          required: required,
                          pattern: {
                            value:
                              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[^\s]{8,20}$/,
                            message: "A valid password is required.",
                          },
                        })}
                        onBlur={() => {
                          handleRevalidatePassword();
                          setPasswordChecklist(false);
                        }}
                        isInvalid={errors.newpassword}
                        aria-invalid={errors.newpassword ? "true" : "false"}
                        onFocus={() => setPasswordChecklist(true)}
                      />
                      <PasswordValidation
                        id="newpassword"
                        password={watch("newpassword")}
                        username={
                          resetPwdArgs.username || resetPwdArgs.email || ""
                        }
                        open={showPasswordChecklist}
                      />
                      <Form.Control.Feedback type="invalid">
                        <TrackErrorMessage
                          message={
                            errors.newpassword && errors.newpassword.message
                          }
                          field="newpassword"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Confirm password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmpassword"
                        ref={register({
                          required: required,
                          validate: (value) => {
                            return value === watch("newpassword");
                          },
                        })}
                        isInvalid={errors.confirmpassword}
                        aria-invalid={errors.confirmpassword ? "true" : "false"}
                      />

                      <Form.Control.Feedback type="invalid">
                        <TrackErrorMessage
                          message={
                            errors.confirmpassword && "Passwords do not match"
                          }
                          field="confirmpassword"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ minWidth: `170px` }}
                    className="w-100"
                  >
                    {isSubmitting ? `Please wait...` : `Submit`}
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
        )}
        <SuccessModal
          show={showSuccess}
          onHide={handleSuccessClose}
          modalSize={"lg"}
        >
          <SubTitle className="text-center mb-1">CONFIRMATION</SubTitle>
          <p>Your password has been reset successfuly.</p>
        </SuccessModal>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ResetPasswordQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default ResetPassword;
